<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">用户车辆信息审批</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-num label="用户手机号" v-model="pageParam.params.mobile" />
                <le-select-local-search label="状态" v-model="pageParam.params.status" :options="statusDic" />
                <le-input label="审批人" v-model="pageParam.params.reviewMobile" />
            </le-search-form>
            <le-pagview ref="userCarAuditPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.userCarAuditList">
                <el-table ref="userCarAuditList" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="id" label="用户ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="用户昵称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="applyTime" label="申请时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.applyTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审批人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审批时间" min-width="240">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" class="a-flex-rcc" width="100">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="审批" placement="top" v-if="row.status == 1">
                                <span @click="handlerAudit(row)"> 
                                    <img src="../../assets/icon/option-audit1.png" class="a-wh-16" />
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="详情" placement="top" v-else>
                                <span @click="handlerAudit(row)"> 
                                    <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
                                </span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../utils/util.js'
export default {
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.getApplyList,
                method: 'post',
                params: {
                    mobile: '',
                    reviewMobile: '',
                    status: ''
                },
                freshCtrl: 1,
            },
            typeDic: [],//车辆类型字典
            batteryVoltageDic: [],//车辆电瓶规格字典
            statusDic: []
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company
        })
    },
    created () {
        this.$getDic('userBikeType','select').then(res=>{ this.typeDic = res; })
        this.$getDic('userBikeBattery','select').then(res=>{ this.batteryVoltageDic = res; })
        this.$getDic('userBikeApplyStatus','select').then(res=>{ this.statusDic = res; })
    },
    mounted() { },
    activated () {
        this.pageParam.freshCtrl++;
    },  
    methods: {
        //获取用户列表
        setTableData(data) {
            this.tableData = data
        },
        handlerRest() {
            this.pageParam.params = {
                mobile: '',
                reviewMobile: '',
                status: ''
            }
            this.handlerSearch()
        },

        handlerSearch() {
            this.$refs['userCarAuditPage'].pageNum = 1
            this.pageParam.freshCtrl++
        },
        //跳转审批\详情
        handlerAudit(row) {
            this.$router.push({
                path: '/userMGT/userMGT-car-audit-info',
                query: {
                    targetUserId: row.userId,
                    id: row.id,
                    applyStatus: row.status,
                    applyTime: row.applyTime
                }
            })
        }

    },
}
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
